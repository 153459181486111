import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

const InputField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 0;
  }

  & .MuiFormLabel-root {
    font-size: 0.9rem;
  }
`;

export const Input = props => {
  return <InputField {...props} />;
};
