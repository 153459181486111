import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ButtonBase = styled.button`
  cursor: pointer;
  border-width: 2px;
  border-style: solid;

  &:focus {
    outline: none;
    border-color: #2491eb;
  }
`;

const ButtonPrimary = styled(ButtonBase)`
  color: white;
  background-color: #e82626;
  padding: 0.65em 1em;
  font-size: 0.9rem;
  border-radius: 1px;
  border-color: transparent;
  display: inline-flex;
  align-items: center;

  &:hover {
    background-color: #cc2626;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: lightgrey;
  }
`;

const types = {
  primary: ButtonPrimary,
};

export const Button = ({ variant = 'primary', children, ...rest }) => {
  const Component = types[variant];
  return <Component {...rest}>{children}</Component>;
};

Button.propTypes = {
  variant: PropTypes.oneOf(['primary']),
};
