import React from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import { GetOfferButtonWithModal } from '@src/ui';

export default function CallToAction() {
  return (
    <Container>
      <Box style={{ paddingTop: '32px', paddingBottom: '64px' }}>
        <Typography variant={'h5'} align={'center'}>
          Получите доступ к IMstream уже сегодня
        </Typography>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '32px',
          }}
        >
          <GetOfferButtonWithModal />
        </Box>
      </Box>
    </Container>
  );
}
