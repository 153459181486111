import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import Alert from '@material-ui/lab/Alert';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  DialogContentText,
  Typography,
  IconButton,
  Box,
  CircularProgress,
  Snackbar,
  FormHelperText,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { yupResolver } from '@hookform/resolvers';

import { getCompanyOffer } from '@src/api/get-company-offer';
import { Button, Input } from '@src/ui';
import File from '@src/assets/svg/file.inline.svg';
import { useAsync } from '@src/utils/hooks';

const Icon = styled(File)`
  fill: white;
  height: 15px;
  width: 15px;
  margin-left: 5px;
`;

const schema = Yup.object({
  name: Yup.string().required('Пожалуйста, заполните.'),
  email: Yup.string()
    .email('Некорректный формат почты.')
    .required('Пожалуйста, заполните.'),
  phone: Yup.string()
    .transform(value => value.replace(new RegExp(/[^0-9]/g), ''))
    .length(11, 'В номере телефона должно быть 11 цифр.'),
});

const PhoneMaskInput = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      type="tel"
      mask={[
        '+',
        /[1-9]/,
        ' ',
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
    />
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ButtonProgressIndicator = styled(CircularProgress)`
  margin-right: 5px;
`;

export const GetOfferButtonWithModal = ({
  buttonText = 'Получить предложение',
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const [isAlertOpen, setAlertOpen] = React.useState(false);
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting, submitCount },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { execute, pending, error, value } = useAsync(getCompanyOffer, false);

  const openModalWindow = () => setOpen(true);
  const closeModalWindow = () => setOpen(false);
  const onSubmit = data => execute(data);

  React.useEffect(() => {
    if (Boolean(value)) {
      reset();
      closeModalWindow();
      setAlertOpen(true);
    }
  }, [reset, value]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={closeModalWindow}
        TransitionComponent={Transition}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle disableTypography>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant={'h6'}>
                Лицензионная музыка без РАО и ВОИС.
              </Typography>
              <Box display={'flex'}>
                <IconButton
                  aria-label="close"
                  onClick={closeModalWindow}
                  size={'small'}
                >
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              Более 100 000 мировых хитов, индивидуальные плей-листы и полное
              юридическое сопровождение. Бесплатный тестовый доступ.
            </DialogContentText>

            <Box mt={2}>
              <Input
                label="Имя"
                fullWidth
                variant={'outlined'}
                inputRef={register()}
                name={'name'}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                disabled={isSubmitting}
              />
            </Box>

            <Box mt={2}>
              <Input
                label="Почта"
                fullWidth
                variant={'outlined'}
                inputRef={register()}
                name={'email'}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                disabled={isSubmitting}
              />
            </Box>

            <Box mt={2}>
              <Input
                label="Телефон"
                fullWidth
                variant={'outlined'}
                inputRef={register()}
                error={Boolean(errors.phone)}
                helperText={errors.phone?.message}
                InputProps={{ inputComponent: PhoneMaskInput, name: 'phone' }}
                disabled={isSubmitting}
              />
            </Box>

            <Box mt={2}>
              <Input
                label="Сообщение"
                multiline
                fullWidth
                rows={6}
                variant={'outlined'}
                inputRef={register}
                name={'message'}
                disabled={isSubmitting}
              />
            </Box>

            {error && (
              <Box mt={1}>
                <FormHelperText error>
                  {submitCount > 1
                    ? 'Пожалуйста, отправьте нам заявку на info@imstream.net'
                    : 'Извините, произошла ошибка, попробуйте отправить заявку еще раз.'}
                </FormHelperText>
              </Box>
            )}

            <Box mt={2} display={'flex'} justifyContent={'flex-end'} mb={2}>
              <Button type={'submit'} disabled={isSubmitting}>
                {pending && <ButtonProgressIndicator size={20} />}
                Оставить заявку
              </Button>
            </Box>
          </DialogContent>
        </form>
      </Dialog>

      <Button onClick={openModalWindow}>
        {buttonText}
        <Icon />
      </Button>

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity="success"
          variant="filled"
        >
          Заявка успешно отправлена!
        </Alert>
      </Snackbar>
    </>
  );
};
