import { useState, useCallback, useEffect } from 'react';

export const useAsync = (asyncFunction, immediate = true) => {
  const [pending, setPending] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  const execute = useCallback(
    data => {
      setPending(true);
      setValue(null);
      setError(null);
      return asyncFunction(data)
        .then(response => setValue(response))
        .catch(error => setError(error))
        .finally(() => setPending(false));
    },
    [asyncFunction]
  );

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return { execute, pending, value, error };
};
